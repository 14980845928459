var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-machines-list"
  }, [_c('b-card', [_c('refueling-list-filtration', {
    attrs: {
      "forEquipment": _vm.forEquipment,
      "type": _vm.type
    }
  }), _c('div', {
    staticClass: "buttons"
  }, [_c('vs-button', {
    directives: [{
      name: "can",
      rawName: "v-can:store",
      value: 'base.resource_report',
      expression: "'base.resource_report'",
      arg: "store"
    }],
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": function () {
        return _vm.openReportModal();
      }
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t('base.refueling.button.reportMth')) + " ")]), _c('vs-button', {
    directives: [{
      name: "can",
      rawName: "v-can:store",
      value: 'base.resource_report',
      expression: "'base.resource_report'",
      arg: "store"
    }],
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": function () {
        return _vm.openReportModal(true);
      }
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t('base.refueling.button.reportFuel')) + " ")])], 1), _c('refueling-list-table', {
    attrs: {
      "forEquipment": _vm.forEquipment
    }
  }), _c('machines-mth-report-modal', {
    attrs: {
      "type": _vm.forEquipment ? 'tool' : 'vehicle'
    },
    on: {
      "ok": _vm.refreshTableData
    }
  }), _c('base-resource-report-edit-modal', {
    on: {
      "ok": _vm.refreshTableData
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }