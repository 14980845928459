import i18n from '@/libs/i18n';
import { ref } from 'vue';
import createTable from '@/hooks/tables/createTable';

const baseMachinesColumns = [
  {
    label: i18n.t('base.equipment.field.name'),
    field: 'name',
    showInDetails: true,
  },
  {
    label: i18n.t('base.global.field.producer'),
    field: 'producer',
  },
  {
    label: i18n.t('base.global.field.category'),
    field: 'category',
  },
  {
    label: i18n.t('base.machines.field.serial_no'),
    field: 'evidenceNumber',
  },
  {
    label: i18n.t('base.equipment.field.status'),
    field: 'status',
  },
  {
    label: i18n.t('base.equipment.field.group'),
    field: 'group',
  },
  {
    label: i18n.t('base.equipment.field.location'),
    field: 'location',
  },
];

export default ref(createTable('base/equipment', baseMachinesColumns, {
  filters: {
    category_ids: [],
    status_ids: [],
    group_ids: [],
  },
}));
