<template>
  <b-tabs
    @activate-tab="changeTab"
  >

    <b-tab
      lazy
      :active="isTabActive('equipment')"
      >
      <template #title>
        <feather-icon icon="ToolIcon" />
        <span>Sprzęt</span>
      </template>

      <equipment-list />
    </b-tab>

    <b-tab lazy :active="isTabActive('reports')">
      <template #title>
        <feather-icon icon="ActivityIcon" />
        <span>Raporty</span>
      </template>

      <refueling-list
        forEquipment
        type="tool"
      />
    </b-tab>

    <b-tab lazy :active="isTabActive('service')">
      <template #title>
        <feather-icon icon="ToolIcon" />
        <span>Serwis</span>
      </template>

      <base-cars-service type="equipment" />
    </b-tab>

    <b-tab lazy :active="isTabActive('schedule')">
      <template #title>
        <feather-icon icon="ClockIcon" />
        <span>Harmonogram</span>
      </template>

      <base-machines-scheduler
        type="tool"
      />
    </b-tab>

  </b-tabs>
</template>

<script>
import {
  BTabs,
  BTab,
} from 'bootstrap-vue';
import { computed } from 'vue';
import { useRouter } from '@/@core/utils/utils';
import RefuelingList from '@/views/base/machines/RefuelingList.vue';
import EquipmentList from './EquipmentList.vue';
import BaseCarsService from '../cars/BaseCarsService.vue';
import BaseMachinesScheduler from '../machines/BaseMachinesScheduler.vue';

export default {
  name: 'BaseEquipment',
  setup() {
    /**
     * route-based tabs should be looked into
     *
     * FIXME: refactor this and probably get rid of
     * activetab meta inside specific routes
     */
    const { route, router } = useRouter();

    const activeTab = computed(() => route.value.meta?.activeTab);
    const isTabActive = (tabName) => activeTab.value === tabName;

    const tabRoutes = {
      0: 'base.equipment',
      1: 'base.equipment.reports',
      2: 'base.equipment.service',
      3: 'base.equipment.schedule',
    };

    const changeTab = (to) => {
      const desiredRoute = tabRoutes?.[to];

      if (!desiredRoute) return;
      if (desiredRoute === route.value.name) return;

      router.push({
        name: desiredRoute,
      });
    };

    return { isTabActive, activeTab, changeTab };
  },
  components: {
    RefuelingList,
    BTabs,
    BTab,
    EquipmentList,
    BaseCarsService,
    BaseMachinesScheduler,
  },
};
</script>
