var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-providers-list-table"
  }, [_c('vs-table', {
    attrs: {
      "showActionButton": "",
      "mode": "remote",
      "columns": _vm.columns,
      "rows": _vm.$store.state.base.reports.reports,
      "totalRecords": _vm.table.totalRecords,
      "current-page": _vm.table.page,
      "checkSort": function () {},
      "perPage": _vm.table.perPage,
      "searchTerm": _vm.searchTerm,
      "actionItems": _vm.actionItems,
      "isLoading": _vm.table.isLoading
    },
    on: {
      "on-row-click": _vm.openDetails,
      "searchTermChange": function (term) {
        return _vm.updateSearchTerm(term);
      },
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'date' ? _c('span', [_vm._v(" " + _vm._s(_vm.getFormattedDate(row.report_date)) + " ")]) : _vm._e(), column.field === 'machine' ? _c('span', [_c('base-resource-label', {
          attrs: {
            "resource": row.resource
          }
        })], 1) : _vm._e(), column.field === 'liters' ? _c('span', [_vm._v(" " + _vm._s(row.amount_fuel || '-') + " ")]) : _vm._e(), column.field === 'mth' ? _c('span', [_vm._v(" " + _vm._s(Number(row.meter_status).toLocaleString()) + " " + _vm._s(_vm.unitStore.getShortcode(row.resource.category.unit_id)) + " ")]) : _vm._e(), column.field === 'worktype' && row.resource_report_work_type ? _c('span', [_vm._v(" " + _vm._s(row.resource_report_work_type.name) + " ")]) : _vm._e(), column.field === 'investment' ? _c('span', [_vm._v(" - ")]) : _vm._e(), column.field === 'reporter' ? _c('span', [_c('user-label', {
          attrs: {
            "id": row.reporter_id,
            "withAvatar": ""
          }
        })], 1) : _vm._e()];
      }
    }])
  }), _vm.currentDetails && _vm.currentDetails.resource ? _c('b-modal', {
    attrs: {
      "id": "modal-report-details",
      "title": "Szczegóły raportu",
      "cancel-variant": "outline-secondary",
      "ok-title": "",
      "size": "lg",
      "cancel": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_vm._v(" - ")];
      },
      proxy: true
    }], null, false, 1353206515),
    model: {
      value: _vm.showDetailsModal,
      callback: function ($$v) {
        _vm.showDetailsModal = $$v;
      },
      expression: "showDetailsModal"
    }
  }, [_c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Data")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.getFormattedDate(_vm.currentDetails.report_date)))])]), _vm.forEquipment ? _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Sprzęt")]), _c('div', {
    staticClass: "value"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'base.equipment.details',
        params: {
          id: _vm.getResourceId(_vm.currentDetails)
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.getResourceName(_vm.currentDetails)) + " ")])], 1)]) : _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Maszyna")]), _c('div', {
    staticClass: "value"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'base.machines.details',
        params: {
          id: _vm.getResourceId(_vm.currentDetails)
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.getResourceName(_vm.currentDetails)) + " ")])], 1)]), !_vm.currentDetails.amount_fuel ? _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Typ pracy")]), _c('div', {
    staticClass: "value"
  }, [_vm.workType ? [_vm._v(" " + _vm._s(_vm.workType.name) + " ")] : _vm._e()], 2)]) : _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Ilość paliwa")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.currentDetails.amount_fuel) + " l ")])]), _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Raportujący")]), _c('div', {
    staticClass: "value"
  }, [_c('user-label', {
    attrs: {
      "id": _vm.currentDetails.reporter_id
    }
  })], 1)]), _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Operator")]), _c('div', {
    staticClass: "value"
  }, [_c('user-label', {
    attrs: {
      "showLink": "",
      "isEmployee": "",
      "data": _vm.currentDetails.operator
    }
  })], 1)]), _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Raportowany przebieg")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(Number(_vm.currentDetails.meter_status).toLocaleString()) + " " + _vm._s(_vm.unitStore.getShortcode(_vm.currentDetails.resource.category.unit_id)) + " ")])]), _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Lokalizacja")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" - ")])]), _c('div', {
    staticClass: "entry"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Uwagi")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.currentDetails.comment) + " ")])])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }