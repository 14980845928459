<template>
  <div class="card shadow-none bg-transparent border-secondary">
    <div class="card-body">
      <h4 class="card-title">
        {{ $t('global.title.filtering') }}
      </h4>
      <b-row>

        <b-col cols="4">
          <b-form-group
            :label="$t('base.global.field.dateFrom')"
            >
            <vs-datepicker
              placeholder="Wybierz datę początkową"
              v-model="dateFrom"
              reset-button
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            :label="$t('base.global.field.dateTo')"
            >
            <vs-datepicker
              placeholder="Wybierz datę końcową"
              v-model="dateTo"
              reset-button
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            :label="$t(`base.global.resourceType.${type}`)"
            >
            <base-resource-picker
              :reduce="(tool) => tool.id"
              multiple
              v-model="filters.resource_ids"
              :excluded="shouldResourceBeExcluded"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row>

        <b-col cols="4">
          <b-form-group
            :label="$t('base.machines.mth.field.operator')"
          >
            <cadre-employee-picker
              withDeleted
              multiple
              v-model="filters.operator_ids"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            :label="$t('base.global.field.reportType')"
          >
            <v-select class="vs-picker" :clearable="false"
              :options="reportTypeLabels"
              v-model="filters.report_type"
              label="name"
              :reduce="type => type.value"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            :label="$t('base.machines.field.worktype')"
          >
            <base-work-type-picker
              multiple
              v-model="filters.work_type_ids"
              :optionFilter="(w) => w.resource_types
                .find((t) => ['vehicle', 'tool'].includes(t.slug))"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <a href="#" @click="clearAllFilters">
        {{ $t('global.table.clearAllFilters') }}
      </a>

    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import {
  BCol, BRow, BFormGroup,
} from 'bootstrap-vue';
import refuelingTable from '@/hooks/tables/base/refuelingTable';
import formatDate from '@/libs/date-formatter';
import { computed } from 'vue';
import { useRouter } from '@/@core/utils/utils';
import router from '@/router';
import CadreEmployeePicker from '@/components/module/cadre/CadreEmployeePicker.vue';
import BaseWorkTypePicker from '@/components/module/base/BaseWorkTypePicker.vue';
import BaseResourcePicker from '@/components/module/base/BaseResourcePicker.vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';

export default {
  name: 'RefuelingListFiltration',
  props: {
    forEquipment: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
  },
  setup(props) {
    const {
      table, filters, clearAllFilters, fetchData,
    } = refuelingTable.value;

    const { route } = useRouter();

    // eslint-disable-next-line no-unused-vars
    const getIdFilterFromRoute = (param) => {
      const routeFilter = route.value.params?.[param];
      if (!routeFilter) return [];

      return [Number(routeFilter)];
    };

    const getReportTypeFromRoute = () => {
      const reportType = route.value.params?.reportType;
      if (!reportType) return null;
      if (reportType !== 'work' && reportType !== 'fuel') return null;

      return reportType;
    };

    // eslint-disable-next-line no-unused-vars
    const resourceType = computed(() => (props.forEquipment ? 'tool' : 'vehicle'));

    refuelingTable.value.shouldUpdateTableOnFilterChange = false;

    // filters are defined as a ref, why can't we access `value` property?
    // FIXME: find out why this happens
    filters.resource_ids = getIdFilterFromRoute('resourceId');
    filters.report_type = getReportTypeFromRoute();

    filters.resource_type = [resourceType.value];

    // FIXME: variable is also defined as a ref, but can't access
    // the `value` property. Also get rid of this timeout workaround
    setTimeout(() => {
      refuelingTable.value.shouldUpdateTableOnFilterChange = true;
      fetchData();
    }, 0);

    const clearFiltersAndChangeRoute = () => {
      router.push({ name: route.value.name, params: {} });
      clearAllFilters();
      filters.resource_type = [resourceType.value];
    };

    const dateFrom = computed({
      get() {
        return formatDate.standard(filters.date_from);
      },
      set(newDate) {
        filters.date_from = newDate;
      },
    });

    const reportTypeLabels = [
      { name: 'Wszystkie raporty', value: null },
      { name: 'Tylko tankowania', value: 'fuel' },
      { name: 'Tylko motogodziny', value: 'work' },
    ];

    const dateTo = computed({
      get() {
        return formatDate.standard(filters.date_to);
      },
      set(newDate) {
        filters.date_to = newDate;
      },
    });

    const shouldResourceBeExcluded = (resource) => {
      if (!props.type) return false;

      const hasAppropriateType = resource.type?.slug === props.type;
      if (!hasAppropriateType) return true;

      const isReportable = resource.category?.be_reported;
      if (!isReportable) return true;

      return false;
    };

    return {
      clearAllFilters: clearFiltersAndChangeRoute,
      table,
      dateFrom,
      dateTo,
      reportTypeLabels,
      filters,
      shouldResourceBeExcluded,
    };
  },
  components: {
    vSelect,
    BCol,
    BRow,
    BFormGroup,

    CadreEmployeePicker,
    BaseWorkTypePicker,
    BaseResourcePicker,
    VsDatepicker,
  },
};
</script>
