var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-equipment-list-table"
  }, [_c('authorization-guard', {
    attrs: {
      "permission": "base.resource.index"
    }
  }, [_c('vs-table', {
    attrs: {
      "showActionButton": "",
      "mode": "remote",
      "columns": _vm.columns,
      "rows": _vm.$store.state.base.equipment.equipment,
      "totalRecords": _vm.table.totalRecords,
      "current-page": _vm.table.page,
      "checkSort": function () {},
      "perPage": _vm.table.perPage,
      "searchTerm": _vm.searchTerm,
      "actionItems": _vm.actionItems,
      "isLoading": _vm.table.isLoading
    },
    on: {
      "on-row-click": _vm.openDetails,
      "searchTermChange": function (term) {
        return _vm.updateSearchTerm(term);
      },
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var _row$producer_model, _row$producer_model$p, _row$last_activity, _row$last_activity$gr;
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'name' ? _c('span', [_vm._v(" " + _vm._s(row.name) + " ")]) : _vm._e(), column.field === 'producer' ? _c('span', [_vm._v(" " + _vm._s((_row$producer_model = row.producer_model) === null || _row$producer_model === void 0 ? void 0 : (_row$producer_model$p = _row$producer_model.producer) === null || _row$producer_model$p === void 0 ? void 0 : _row$producer_model$p.name) + " ")]) : _vm._e(), column.field === 'evidenceNumber' ? _c('span', [_vm._v(" " + _vm._s(row.serial_number) + " ")]) : _vm._e(), column.field === 'category' ? _c('span', [_vm._v(" " + _vm._s(row.category.name) + " ")]) : _vm._e(), column.field === 'status' ? _c('span', [_c('status-badge', {
          attrs: {
            "item": row.status
          }
        })], 1) : _vm._e(), column.field === 'group' ? _c('span', [_vm._v(" " + _vm._s((_row$last_activity = row.last_activity) === null || _row$last_activity === void 0 ? void 0 : (_row$last_activity$gr = _row$last_activity.group) === null || _row$last_activity$gr === void 0 ? void 0 : _row$last_activity$gr.name) + " ")]) : _vm._e()];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }