<template>
  <div class="base-machines-list">
    <b-card>
      <refueling-list-filtration
        :forEquipment="forEquipment"
        :type="type"
      />

      <div class="buttons">
        <vs-button
          vs-variant="primary"
          @click="() => openReportModal()"
          v-can:store="'base.resource_report'"
        >
          + {{ $t('base.refueling.button.reportMth') }}
        </vs-button>

        <vs-button
          vs-variant="primary"
          @click="() => openReportModal(true)"
          v-can:store="'base.resource_report'"
        >
          + {{ $t('base.refueling.button.reportFuel') }}
        </vs-button>
      </div>

      <refueling-list-table :forEquipment="forEquipment" />

      <machines-mth-report-modal
        :type="forEquipment ? 'tool' : 'vehicle'"
        @ok="refreshTableData"
      />
      <base-resource-report-edit-modal
        @ok="refreshTableData"
      />
    </b-card>
  </div>
</template>

<script>
import RefuelingListFiltration from '@/components/views/base/machines/refueling/RefuelingListFiltration.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import {
  BCard,
} from 'bootstrap-vue';
import RefuelingListTable from '@/components/views/base/machines/refueling/RefuelingListTable.vue';
import MachinesMthReportModal from '@/components/views/base/machines/modals/MachinesMthReportModal.vue';
import refuelingTable from '@/hooks/tables/base/refuelingTable';
import useBaseModals from '@/hooks/base/useBaseModals';
import BaseResourceReportEditModal from '@/components/views/base/machines/modals/BaseResourceReportEditModal.vue';

export default {
  name: 'RefuelingList',
  props: {
    forEquipment: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
  },
  setup() {
    const refreshTableData = () => {
      refuelingTable.value.fetchData();
    };

    const { modalCreateReport } = useBaseModals();
    const openReportModal = (reportFuel = false) => modalCreateReport.value.open({ reportFuel });

    return { refreshTableData, openReportModal };
  },
  components: {
    RefuelingListFiltration,
    RefuelingListTable,
    VsButton,

    BCard,
    MachinesMthReportModal,
    BaseResourceReportEditModal,
  },
};
</script>

<style lang="sass" scoped>
.buttons
  margin-bottom: 20px
  .vs-button
    &:not(:first-child)
      margin-left: 10px
</style>
