import showToast from '@/libs/toasts';
import { FetchWorkTypesError } from '@/libs/toasts/feedback/module/base';
import useBaseApi from '../useBaseApi';
import useBaseStore from '../useBaseStore';

export default function useBaseWorkType() {
  const { workTypes } = useBaseStore();

  const fetchWorkTypes = (force = false) => {
    if (workTypes.value.length > 0 && !force) return workTypes;

    const req = useBaseApi()
      .fetchWorkTypes()
      .then(({ data }) => {
        workTypes.value = data.data;
      })
      .catch(() => showToast(FetchWorkTypesError));

    return req;
  };

  const getWorkType = (workTypeId) => {
    fetchWorkTypes();

    return workTypes.value.find((type) => type.id === workTypeId);
  };

  return { workTypes, fetchWorkTypes, getWorkType };
}
