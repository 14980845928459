<template>
  <div class="crm-providers-list-table">
    <vs-table showActionButton
      mode="remote"
      :columns="columns"
      :rows="$store.state.base.reports.reports"
      :totalRecords="table.totalRecords"
      :current-page="table.page"
      :checkSort="() => {}"
      :perPage="table.perPage"
      :searchTerm="searchTerm"
      :actionItems="actionItems"
      :isLoading="table.isLoading"
      @on-row-click="openDetails"
      @searchTermChange="(term) => updateSearchTerm(term)"
      @on-page-change="onPageChange"
      @on-per-page-change="onPageChange"
      >
      <template v-slot:row="{ column, row }" >
        <span v-if="column.field === 'date'">
          {{ getFormattedDate(row.report_date) }}
        </span>
        <span v-if="column.field === 'machine'">
          <base-resource-label
            :resource="row.resource"
          />
        </span>
        <span v-if="column.field === 'liters'">
          {{ row.amount_fuel || '-' }}
        </span>
        <span v-if="column.field === 'mth'">
          {{ Number(row.meter_status).toLocaleString() }}
          {{ unitStore.getShortcode(row.resource.category.unit_id) }}
        </span>
        <span v-if="column.field === 'worktype' && row.resource_report_work_type">
          {{ row.resource_report_work_type.name }}
        </span>
        <span v-if="column.field === 'investment'">
          -
        </span>
        <span v-if="column.field === 'reporter'">
          <user-label :id="row.reporter_id" withAvatar />
        </span>
      </template>
    </vs-table>

    <!-- TODO: import as a component -->
    <b-modal
      id="modal-report-details"
      title="Szczegóły raportu"
      cancel-variant="outline-secondary"
      ok-title=""
      size="lg"
      cancel
      v-model="showDetailsModal"
      v-if="currentDetails && currentDetails.resource"
    >
      <div class="entry">
        <div class="label">Data</div>
        <div class="value">{{ getFormattedDate(currentDetails.report_date) }}</div>
      </div>

      <div class="entry" v-if="forEquipment">
        <div class="label">Sprzęt</div>
        <div class="value">
          <router-link :to="{
            name: 'base.equipment.details', params: { id: getResourceId(currentDetails) }
          }">
            {{ getResourceName(currentDetails) }}
          </router-link>
        </div>
      </div>

      <div class="entry" v-else>
        <div class="label">Maszyna</div>
        <div class="value">
          <router-link :to="{
            name: 'base.machines.details', params: { id: getResourceId(currentDetails) }
          }">
            {{ getResourceName(currentDetails) }}
          </router-link>
        </div>
      </div>

      <div class="entry" v-if="!currentDetails.amount_fuel">
        <div class="label">Typ pracy</div>
        <div class="value">
          <template v-if="workType">
            {{ workType.name }}
          </template>
        </div>
      </div>

      <div class="entry" v-else>
        <div class="label">Ilość paliwa</div>
        <div class="value">
          {{ currentDetails.amount_fuel }} l
        </div>
      </div>

      <div class="entry">
        <div class="label">Raportujący</div>
        <div class="value">
          <user-label :id="currentDetails.reporter_id" />
        </div>
      </div>

      <div class="entry">
        <div class="label">Operator</div>
        <div class="value">
          <user-label showLink isEmployee :data="currentDetails.operator" />
        </div>
      </div>

      <div class="entry">
        <div class="label">Raportowany przebieg</div>
        <div class="value">
          {{ Number(currentDetails.meter_status).toLocaleString() }}
          {{ unitStore.getShortcode(currentDetails.resource.category.unit_id) }}
        </div>
      </div>

      <div class="entry">
        <div class="label">Lokalizacja</div>
        <div class="value">
          <!-- TODO whenever investments module will be ready -->
          -
        </div>
      </div>

      <div class="entry">
        <div class="label">Uwagi</div>
        <div class="value">
          {{ currentDetails.comment }}
        </div>
      </div>

      <template #modal-footer>
        -
      </template>
    </b-modal>
  </div>
</template>

<script>
import { computed, ref } from 'vue';

import { BModal } from 'bootstrap-vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import UserLabel from '@/components/ui/UserLabel.vue';

import useCoreUnitStore from '@/stores/module/core/useCoreUnitStore';
import refuelingTable from '@/hooks/tables/base/refuelingTable';
import formatDate from '@/libs/date-formatter';
import useMachineReports from '@/hooks/base/machine/useMachineReports';
import useBaseWorkType from '@/hooks/base/assignables/useBaseWorkType';
import useBaseModals from '@/hooks/base/useBaseModals';
import BaseResourceLabel from '../../resource/BaseResourceLabel.vue';

export default {
  name: 'RefuelingListTable',
  props: {
    forEquipment: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      table, updateSearchTerm, searchTerm, columns,
      onPageChange, rows,
    } = refuelingTable.value;
    const unitStore = useCoreUnitStore();

    const { deleteReport } = useMachineReports();

    const getFormattedDate = (date) => formatDate.baseReport(date);

    const resourceName = computed(() => (props.forEquipment ? 'tools' : 'vehicles'));

    // FIXME: machine shouldn't be specified as an array.
    //        this response should be fixed in backend
    const getResourceName = (row) => row.resource?.name;
    const getResourceId = (row) => row.base_resource_id;
    const getResource = (row) => row?.[resourceName.value]?.[0];

    const { modalEditReport } = useBaseModals();
    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        callback: (obj) => {
          modalEditReport.value.open({ ...obj });
        },
      },
      {
        title: 'Usuń',
        icon: 'Trash2',
        callback: (obj) => {
          deleteReport(obj);
        },
      },
    ];

    const showDetailsModal = ref(false);
    const currentDetails = ref({});

    const openDetails = (data) => {
      showDetailsModal.value = true;
      currentDetails.value = data.row;
    };

    const { getWorkType } = useBaseWorkType();

    const workType = computed(() => {
      const workTypeId = currentDetails.value?.base_work_type_id;
      if (!workTypeId) return null;

      return getWorkType(workTypeId);
    });

    return {
      table,
      rows,
      updateSearchTerm,
      searchTerm,
      columns,
      onPageChange,
      actionItems,
      unitStore,

      getFormattedDate,
      getResourceName,
      getResourceId,
      getResource,
      showDetailsModal,
      openDetails,
      currentDetails,
      workType,
    };
  },
  components: {
    VsTable,
    UserLabel,
    BModal,
    BaseResourceLabel,
  },
};
</script>

<style lang="sass" scoped>
.entry
  margin: 20px 0

  .label
    opacity: 0.7

  .value
    font-size: 14pt
    font-weight: 500
</style>
