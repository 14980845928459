<template>
  <div class="card shadow-none bg-transparent border-secondary">
    <div class="card-body">
      <h4 class="card-title">
        {{ $t('global.title.filtering') }}
      </h4>
      <b-row>
        <b-col cols="4">
          <b-form-group
            :label="$t('base.global.field.category')"
          >
            <base-category-picker
              multiple
              v-model="filters.category_ids"
              :excluded="(option) => option.type?.slug !== 'tool'"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            :label="$t('base.global.field.status')"
          >
            <base-status-picker
              multiple
              :excluded="({ types }) => !types.find((t) => t.slug === 'tool')"
              v-model="filters.status_ids"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Grupa"
          >
            <base-group-picker
              multiple
              v-model="filters.group_ids"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <a href="#" @click="clearAllFilters">
        {{ $t('global.table.clearAllFilters') }}
      </a>
    </div>
  </div>
</template>

<script>
import { BCol, BRow, BFormGroup } from 'bootstrap-vue';
import BaseCategoryPicker from '@/components/module/base/BaseCategoryPicker.vue';
import BaseGroupPicker from '@/components/module/base/BaseGroupPicker.vue';
import BaseStatusPicker from '@/components/module/base/BaseStatusPicker.vue';
import baseEquipmentTable from '@/hooks/tables/base/equipment';

export default {
  name: 'EquipmentListFiltration',
  setup() {
    const { table, filters, clearAllFilters } = baseEquipmentTable.value;

    return {
      filters,
      clearAllFilters,
      table,
    };
  },
  components: {
    BCol,
    BRow,
    BFormGroup,

    BaseGroupPicker,
    BaseCategoryPicker,
    BaseStatusPicker,
  },
};
</script>

<style scoped>

</style>
