var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card shadow-none bg-transparent border-secondary"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h4', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.title.filtering')) + " ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.global.field.dateFrom')
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę początkową",
      "reset-button": ""
    },
    model: {
      value: _vm.dateFrom,
      callback: function ($$v) {
        _vm.dateFrom = $$v;
      },
      expression: "dateFrom"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.global.field.dateTo')
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę końcową",
      "reset-button": ""
    },
    model: {
      value: _vm.dateTo,
      callback: function ($$v) {
        _vm.dateTo = $$v;
      },
      expression: "dateTo"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t(`base.global.resourceType.${_vm.type}`)
    }
  }, [_c('base-resource-picker', {
    attrs: {
      "reduce": function (tool) {
        return tool.id;
      },
      "multiple": "",
      "excluded": _vm.shouldResourceBeExcluded
    },
    model: {
      value: _vm.filters.resource_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "resource_ids", $$v);
      },
      expression: "filters.resource_ids"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.machines.mth.field.operator')
    }
  }, [_c('cadre-employee-picker', {
    attrs: {
      "withDeleted": "",
      "multiple": ""
    },
    model: {
      value: _vm.filters.operator_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "operator_ids", $$v);
      },
      expression: "filters.operator_ids"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.global.field.reportType')
    }
  }, [_c('v-select', {
    staticClass: "vs-picker",
    attrs: {
      "clearable": false,
      "options": _vm.reportTypeLabels,
      "label": "name",
      "reduce": function (type) {
        return type.value;
      }
    },
    model: {
      value: _vm.filters.report_type,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "report_type", $$v);
      },
      expression: "filters.report_type"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.machines.field.worktype')
    }
  }, [_c('base-work-type-picker', {
    attrs: {
      "multiple": "",
      "optionFilter": function (w) {
        return w.resource_types.find(function (t) {
          return ['vehicle', 'tool'].includes(t.slug);
        });
      }
    },
    model: {
      value: _vm.filters.work_type_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "work_type_ids", $$v);
      },
      expression: "filters.work_type_ids"
    }
  })], 1)], 1)], 1), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.clearAllFilters
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.table.clearAllFilters')) + " ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }